export function Body({children}) {
    return (
        <div style={{
            marginTop: 120,
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            width: "100%",
            paddingBottom: 20
        }}>
            <div style={{width: "85%", textAlign: 'center'}}>
                {children}
            </div>
        </div>
    )
}