import {useEffect, useState} from "react";
import {Page} from "../components/layout/Page";
import {Separator} from "../components/layout/Separator";

export function TeamPage(){

    const [floatImg, setFloatImg] = useState(window.innerWidth > 500)
    const breakHandler = () => {
        if (window.innerWidth > 500) {
            setFloatImg(true)
        } else {
            setFloatImg(false)
        }
    }
    useEffect(() => {
        window.addEventListener('resize', breakHandler)
        return () => window.removeEventListener('resize', breakHandler)
    }, []);

    return (
        <Page>
            <div style = {{height: 'fit-content', marginBottom: 80}}>
                <Chris floatImg={floatImg}/>
                <Separator/>
                <Dan floatImg={floatImg}/>
            </div>
        </Page>
    )
}

function Chris({floatImg}){
    return(
        <div style={{display: 'inline-block'}}>
            <Picture
                url={"/images/chris.png"}
                name={"Chris Hartley"}
                title={'Software Architect, Founder'}
                floatImg={floatImg}
            />

            <div style={{textAlign: 'left'}}>
                <p>
                    During my 8 years in the software industry, I have lead engineering teams in cross-functional
                    roles to deliver complex software solutions for multi-million dollar projects. I'm experienced
                    as a Software Engineer, Software Architect, and Project Manager with a wide variety of technologies
                    and product types. I have helped lead teams and engineer products for Cigna (Pharma Web Portal),
                    IBM (Data Set Key Rotation, IBM Z Backup Resiliency), and BMC Software (FDR Web)
                    in addition to developing applications for small businesses.
                </p>
                <p>
                    As someone dedicated to creating high-quality products, I see how much <strong>bad </strong>
                    software there is out there, and want to help make sure your business software doesn't leave
                    users or customers cursing at their screens. I'll bring my passion for user experience,
                    quality, and performance to your team and help you deliver the best software possible.
                </p>
            </div>
        </div>
    )
}

function Dan({floatImg}){
    return (
        <div style={{height: '50%'}}>
            <Picture
                url={"/images/dan.jpg"}
                name={"Dan Snyder"}
                title={'Software Engineer, Founder'}
                floatImg={floatImg}
            />
            <div style={{textAlign: 'left'}}>
                <p>
                    With over 13 years of experience in software development, I bring a wealth of expertise in
                    both embedded systems and enterprise-level solutions. I spent my first few years programming
                    microcontrollers for use in various types of robotics before transitioning to software development
                    for industry-leading
                    platforms. I currently work as a Software Engineer contributing to the development of innovative
                    solutions for large-scale enterprises like IBM (Cloud Data Manager) and BMC Software (FDReport).
                </p>
                <p>
                    In addition to my corporate roles, I also offer consulting services to small businesses, helping
                    them leverage technology to streamline operations and drive growth. Whether it's custom software
                    development, system integrations, or strategic tech planning, I am passionate about using my
                    technical knowledge to
                    solve real-world problems.

                </p>
            </div>
        </div>

    )

}

function Picture({url, name, title, floatImg}) {
    return (
        <div>
            <div style={{
                float: floatImg ? 'left' : 'none',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column'
            }}>
                <div style={{marginRight: floatImg ? 30 : 0}}>
                    <img rel="preload" src={url}
                         align="top"
                         alt="its-a-me"
                         style={{
                             width: '27vh',
                             display: 'inline-block',
                             marginTop: 10
                         }}
                    />
                    <p style={{textAlign: "center", fontSize: '0.95em'}}>
                        <strong>
                            {name}<br/>
                            {title}
                        </strong>
                    </p>
                </div>
            </div>
        </div>
    )
}
