import './App.css';
import {BrowserRouter, Route, Routes} from "react-router";
import {HomePage} from "./pages/HomePage";
import {TeamPage} from "./pages/TeamPage";
import {ContactPage} from "./pages/ContactPage";
import "bootstrap-icons/font/bootstrap-icons.css";

export function App() {
    return(
        <>
            <BrowserRouter>
                <Routes>
                    <Route path={"/"} element={<HomePage/>}/>
                    <Route path={"/team"} element={<TeamPage/>}/>
                    <Route path={"/contact"} element={<ContactPage/>}/>
                </Routes>
            </BrowserRouter>
        </>
    )
}



