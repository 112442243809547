import {fsiTheme} from "../../theme";
import {useEffect} from "react";
import {Header} from "./Header";
import {Footer} from "./Footer";
import {Body} from "./Body";

export function Page({children}) {
    const style = {
        backgroundColor: fsiTheme.background,
        minHeight: '100vh',
        overflowX: 'auto',
        overflowY: 'auto',
        minWidth: 300,
        fontSize: '1.2em',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    }
    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        })
    }, []);
    return (
        <div style={style}>
            <Header/>
            <Body>
                {children}
            </Body>
            <Footer/>
        </div>
    )
}