import {fsiTheme} from "../../theme";
import {useEffect, useState} from "react";
import {Button} from "../Button";
import {Offcanvas} from "react-bootstrap";

export function Header() {
    const style = {
        height: 80,
        backgroundColor: fsiTheme.header,
        boxShadow: `5px 6px ${fsiTheme.headerShadow}`,
        width: "calc(100% - 30px)",
        marginTop: 10,
        borderRadius: 10,
        display: 'flex',
        justifyContent: 'space-between',
    }
    const [show, setShow] = useState(false)
    const [shrinkLogo, setShrinkLogo] = useState(window.innerWidth < 500)
    const breakHandler = () => {
        if (window.innerWidth < 500) {
            setShrinkLogo(true)
        } else {
            setShrinkLogo(false)
        }
    }
    useEffect(() => {
        window.addEventListener('resize', breakHandler)
        return () => window.removeEventListener('resize', breakHandler)
    }, []);
    return (
        <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            fontSize: '0.85em',
            backgroundColor: fsiTheme.background,
            zIndex: 1000,
            position: 'fixed',
            top: 0
        }}>
            <div style={style}>
                <a href={'/'}>
                    <img rel="preload" src="/images/FULLSTACK%20SOFTWARE%20INNOVATIONS.png"
                         alt={"Fullstack Software Innovations (Logo)"}
                         style={{marginTop: shrinkLogo ? 10 : 5, marginLeft: 5, height: shrinkLogo ? 60 : 75}}/>

                </a>
                <Button onClick={() => setShow(true)} color={fsiTheme.header}>
                    <i className="bi bi-list" style={{fontSize: '1.5em'}}/>
                </Button>
            </div>


            <Offcanvas
                show={show}
                placement={'end'}
                onHide={() => setShow(false)}
                style={{backgroundColor: fsiTheme.background}}
            >

                <Offcanvas.Header
                    closeButton
                    style={{
                        backgroundColor: fsiTheme.header,
                        color: fsiTheme.buttonText,
                        fontSize: '1.5em'
                    }}>
                    <img rel="preload" src="/images/FULLSTACK%20SOFTWARE%20INNOVATIONS.png"
                         alt={"Fullstack Software Innovations (Logo)"}
                         style={{marginTop: shrinkLogo ? 10 : 5, marginLeft: 5, height: 75, cursor: "pointer"}}
                         onClick={() => setShow(false)}
                    />

                </Offcanvas.Header>
                <Offcanvas.Body
                    style={{
                        fontSize: '1.5em'
                    }}>

                    <div style={{display: 'flex', flexDirection: 'column', gap: 20}}>

                        <a href={'/'} style={{color: 'black', textDecoration: 'none'}}>
                            <i className="bi bi-house-door-fill"> </i>
                            Home
                        </a>

                        <a href={'/team'} style={{color: 'black', textDecoration: 'none'}}>
                            <i className="bi-file-person-fill"> </i>
                            Meet the team
                        </a>

                        <a href={'/contact'} style={{color: 'black', textDecoration: 'none'}}>
                            <i className="bi bi-envelope-paper-heart-fill"> </i>
                            Contact us
                        </a>
                    </div>

                </Offcanvas.Body>
            </Offcanvas>
        </div>

    )

}