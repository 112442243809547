import {Form, FormControl, FormLabel, FormText, Modal, ModalHeader} from "react-bootstrap";
import {useEffect, useState} from "react";
import {Button} from "../components/Button";
import {fsiTheme} from "../theme";
import {Page} from "../components/layout/Page";

const defaultSubject = "Let's discuss how FSI can help my business"
const defaultBody = "Hi,\nI'm looking for more info about FSI and would like to get in touch!"
const baseLink = "mailto:info@fullstack.software"

function formatLink(subject, body){
    return `${baseLink}?subject=${subject}&body=${body.replaceAll('\n', '%0D%0A')}`
}
export function ContactPage() {
    const [subject, setSubject] = useState(defaultSubject)
    const [body, setBody] = useState(defaultBody)
    const [emailLink, setEmailLink] = useState(formatLink(defaultSubject, defaultBody))

    useEffect(()=>{
        setEmailLink(formatLink(subject, body))
    }, [subject, body])
    return (
        <Page>
                <div style={{
                    backgroundColor: fsiTheme.secondaryBackground,
                    minWidth: 300,
                    padding: 25
                }}>
                    <h3>
                        Want to talk about how Fullstack Software Innovations can help your business?
                    </h3>

                    <Form style = {{textAlign: 'left'}}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label column={'lg'}>Subject</Form.Label>
                            <Form.Control
                                type="input"
                                placeholder="Email subject..."
                                value={subject}
                                onChange = {(e) => setSubject(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label column={'lg'}>Message</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                value={body}
                                onChange={(e) => setBody(e.target.value)} />
                        </Form.Group>
                    </Form>

                    <a href={emailLink}>
                        <Button style = {{width: '100%'}} >
                            Send
                        </Button>
                    </a>

                    <br/>
                    <br/>
                    <h5>Use the form above or send an email to <a
                        href={emailLink}>info@fullstack.software</a></h5>
                </div>
        </Page>
    )
}