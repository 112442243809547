import {useEffect, useState} from "react";
import {Button} from "../components/Button";
import {useNavigate} from "react-router";
import {Page} from "../components/layout/Page";
import {Separator} from "../components/layout/Separator";

export function HomePage(){


    return (
        <Page>
            <About/>
        </Page>
    );
}

function About(){
    const nav = useNavigate()
    const [floatImg, setFloatImg] = useState(window.innerWidth > 1050)
    const breakHandler = () =>{
        if(window.innerWidth > 1050){
            setFloatImg(true)
        } else{
            setFloatImg(false)
        }
    }
    useEffect(() => {
        window.addEventListener('resize', breakHandler)
        return () => window.removeEventListener('resize', breakHandler)
    }, []);
    return(
        <>


            <div>
                <Companies floatImg={floatImg}/>
                <Separator/>
                <FSICube floatImg={floatImg}/>
                <WhyUs/>
                <br/>
                <Buttons/>

            </div>
        </>
    )
}

function Companies({
                       floatImg
                   }){
    return (
        <div style={{textAlign: 'center'}}>
            <h1>
                Companies That Trust Our Software Experts
            </h1>
            <div style={{marginLeft: floatImg ? 75 : 0}}>
                <img rel="preload" src="/images/IBM Logo.png" style={{width: '15%', minWidth: 200, marginTop: 20}}/>
                <img rel="preload" src="/images/Cigna-logo.png"
                     style={{
                         width: '25%',
                         minWidth: 250,
                         marginBottom: 0,
                         marginTop: 20,
                         marginLeft: 20
                     }}/>
                <img rel="preload" src="/images/BMC%20Logo.png"
                     style={{width: '20%', minWidth: 150, marginTop: floatImg ? 35 : 45}}/>
                <img rel="preload" src="/images/21CS Logo.png" style={{width: '20%', minWidth: 150, marginBottom: 0, marginTop: floatImg ? -34 : 0}}/>

            </div>
        </div>
    )
}

function Buttons() {
    const nav = useNavigate()
    return (
        <div style={{margin: '30px 0 50px'}}>
            <Button style={{width: '50%'}} onClick={() => nav("/team")}>
                Meet The Team
            </Button>

            <Button style={{width: '45%', marginLeft: "5%"}} onClick={() => nav("/contact")}>
                Get In Touch
            </Button>
        </div>
    )
}

function WhyUs() {
    return (
        <div style={{textAlign: 'left'}}>
            <h2>
                Why use FSI for your software development and project management needs?
            </h2>
            <p>
                Our software experts at Fullstack Software Innovations have extensive experience leading the
                development of multi-million dollar products for businesses such as IBM, BMC Software, and
                Cigna Healthcare. We have a proven track record of delivering innovative, scalable, and
                high-performance Enterprise Software and Software-as-a-Service solutions that support operations
                for banks, insurance companies, pharmaceutical manufacturers, and retailers all over the world!
            </p>
            <br/>
            <h2>
                How can FSI's software experts help your business?
            </h2>
            <p>
                The expertise that Fullstack Software Innovations brings to your organization spans the entire software
                development lifecycle. From conceptualization and architecture to deployment and
                optimization, we've done it all. We can help your business manage cross-functional teams and drive
                collaboration across engineering, UI design, and product management to deliver software
                on time and within budget. Our leadership and technical skills have consistently resulted in the
                creation of impactful, market-leading products that drive revenue growth and organizational success.
            </p>
            <br/>
            <h2>
                Why should businesses consider custom-built software solutions over off-the-shelf alternatives?
            </h2>
            <p>
                While many businesses use off-the-shelf solutions for their enterprise software, there
                is no comparison to having custom built software from a team of engineers and managers that
                put your business and customer's needs first. Fullstack Software Innovations can help your
                business improve
                customer satisfaction and retention with software tools that meet your needs.
                Whether it's business analytics, web portals, or on-site software solutions, FSI has you covered.
            </p>
        </div>
    )
}

function FSICube({floatImg}) {
    return (
        <div style={{
            float: floatImg ? 'right' : 'none',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
        }}>
            <div style={{marginLeft: floatImg ? 40 : 0}}>
                <img rel="preload" src="/images/FSI Cube.png"
                     align="top"
                     alt="its-a-me"
                     style={{
                         width: '35vh', minWidth: 250,
                         display: 'inline-block',
                         marginTop: 10
                     }}
                />
            </div>

        </div>
    )
}