import {fsiTheme} from "../../theme";

export function Footer() {
    return (
        <div
            style={{
                height: 60,
                backgroundColor: fsiTheme.footer,
                padding: 10,
                fontSize: '.75em'
            }}
        >
            Site design / logo © 2024 Fullstack Software Innovations
        </div>
    )
}