import {Button as BSButton } from 'react-bootstrap'
import {fsiTheme} from "../theme";

export function Button({children, style, onClick, color}) {

    return (
        <>
            <BSButton
                size={'lg'}
                onClick={onClick}
                style = {{
                    backgroundColor: color ?? fsiTheme.button,
                    color: fsiTheme.buttonText,
                    borderColor: color ?? fsiTheme.button,
                    ...style
                }}>
                {children}
            </BSButton>
        </>
    )
}