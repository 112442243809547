import {fsiTheme} from "../../theme";

export function Separator() {
    return (
        <p style={{
            borderBottom: `3px solid ${fsiTheme.separator}`,
            borderTop: `3px solid ${fsiTheme.separator}`,
            margin: '30px 0 50px',
            borderRadius: 5
        }}/>

    )
}